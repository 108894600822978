<template>
  <v-card flat>
    <v-row class="mx-2">
      <v-col cols="12" xl="3" lg="3" md="3" sm="6" xs="12" class="px-0">
        <v-card-actions id="card-custom">
          <v-autocomplete
            :items="imgGroups"
            v-model="imgGroup"
            :loading="loadingGroup"
            placeholder="Grup Gambar"
            outlined
            dense
            hide-details
            item-text="img_group_name"
            item-value="img_group_id"
            @change="changeGroup()"
            @click:clear="changeGroup()"
            clearable
          ></v-autocomplete>
        </v-card-actions>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="3" sm="6" xs="12" class="px-0">
        <v-card-actions id="card-custom">
          <v-autocomplete
            :items="imgTypes"
            v-model="imgType"
            :loading="loadingType"
            placeholder="Tipe Gambar"
            outlined
            dense
            hide-details
            item-text="deskripsi"
            item-value="image_id"
            clearable
          ></v-autocomplete>
        </v-card-actions>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6" sm="6" xs="12" class="px-0">
        <v-btn color="#FBB005" class="px-10 mt-2 ml-2" @click="getDigitalList">
          <span class="subtitle-2 text-capitalize">Cari</span>
        </v-btn>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="3" sm="12" xs="12" class="px-0">
        <v-card-actions class="pr-3">
          <v-spacer />
          <v-btn
            @click="() => (visible = true)"
            text
            class="text-capitalize"
            color="primary"
            >Tambah File<v-icon class="ml-2" color="primary"
              >add_circle</v-icon
            ></v-btn
          >
        </v-card-actions>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          id="datatable"
          class="mt-10"
          :headers="headers"
          :items="files"
          :loading="loading"
          loading-text="Loading... Please wait"
          v-model="selected"
          :show-select="selection"
          item-key="no"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <template v-if="item.document">
              <v-btn
                @click="downloadTemplate(item.document.url, item.name)"
                fab
                small
                color="primary"
                class="d-none d-sm-flex"
                ><v-icon>vertical_align_bottom</v-icon></v-btn
              >
              <v-btn
                @click="downloadTemplate(item.document.url, item.name)"
                text
                small
                color="primary"
                class="d-flex d-sm-none"
                >Download</v-btn
              >
            </template>
          </template>
          <template v-slot:[`item.document`]="{ item }">
            <v-img
              :src="item.document.url"
              :alt="item.document.name"
              v-if="
                isImage(item.document.extension) && $vuetify.breakpoint.smAndUp
              "
              contain
              width="400"
              height="150"
              class="my-2"
            />
            <a
              v-else
              :href="item.document.url"
              target="_blank"
              class="subtitle-2 hover-primary"
            >
              {{ item.name }}
            </a>
          </template>
          <template v-slot:no-data>
            <ContentNotFound message="File digital tidak ada" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-btn
          depressed
          color="error"
          class="px-9 mx-2 font-weight-bold float-right"
          @click="deleteMultiItem"
          :disabled="selected && selected.length == 0"
          :loading="loadingDelete"
        >
          REMOVE
          <v-icon right dark>delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="500">
      <v-form
        id="form"
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="save()"
      >
        <v-card class="pa-5 rounded-lg">
          <v-btn @click="visible = false" x-small icon class="float-right">
            <v-icon>close</v-icon>
          </v-btn>
          <v-card-text class="subtitle-2 px-0 font-weight-bold">
            Tambah File
          </v-card-text>
          <v-divider class="mb-5" />
          <v-card-text class="subtitle-2 px-0 pb-2 font-weight-regular pt-0">
            Nama File
          </v-card-text>
          <v-text-field
            placeholder="Ketik Nama File"
            outlined
            dense
            v-model="form.name"
            :rules="[v => !!v || 'Nama harus diisi']"
          ></v-text-field>
          <v-card-text class="subtitle-2 px-0 pb-2 font-weight-regular pt-0">
            Image Group
          </v-card-text>
          <v-autocomplete
            :items="imgGroups"
            v-model="form.img_group_id"
            :loading="loadingGroupDialog"
            placeholder="Grup Gambar"
            outlined
            dense
            item-text="img_group_name"
            item-value="img_group_id"
            @change="changeGroup('Dialog')"
            @click:clear="changeGroup('Dialog')"
            :rules="[v => !!v || 'Grup Gambar harus diisi']"
            clearable
          ></v-autocomplete>
          <v-card-text class="subtitle-2 px-0 pb-2 font-weight-regular pt-0">
            Image Type
          </v-card-text>
          <v-autocomplete
            :items="imgTypesDialog"
            v-model="form.image_id"
            :loading="loadingTypeDialog"
            placeholder="Tipe Gambar"
            outlined
            dense
            item-text="deskripsi"
            item-value="image_id"
            :rules="[v => !!v || 'Tipe gambar harus diisi']"
            clearable
          ></v-autocomplete>
          <v-card-text
            class="subtitle-2 px-0 pb-2 mt-5 font-weight-regular pt-0"
          >
            Pilih File
          </v-card-text>
          <FileDraggable ref="fileDraggable" @on-change="handleOnChange" />
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Tambah</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>
<script>
import PublicService from "@/services/resources/public.service";
import PegawaiService from "@/services/resources/pegawai.service";
const ContentNotFound = () => import("@/components/Content/NotFound");
const FileDraggable = () => import("@/components/File/Draggable");
import { ACCEPT_IMPORT } from "@/common/constant";

export default {
  components: {
    ContentNotFound,
    FileDraggable
  },
  data() {
    return {
      id: this.$route.params ? this.$route.params.employeeId : null,
      valid: false,
      visible: false,
      selected: [],
      selection: true,
      loadingGroup: false,
      loadingType: false,
      loadingGroupDialog: false,
      loadingTypeDialog: false,
      loadingDelete: false,
      formLoading: false,
      headers: [
        { text: "NAMA FILE", value: "name", sortable: true, align: "center" },
        {
          text: "NAMA GRUP",
          value: "image_group_name",
          sortable: false,
          align: "center"
        },
        {
          text: "DESKRIPSI",
          value: "image_description",
          sortable: false,
          align: "center"
        },
        {
          text: "GAMBAR/FILE",
          value: "document",
          sortable: false,
          align: "center"
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      files: [],
      form: {
        name: null,
        img_group_id: null,
        image_id: null
      },
      file: null,
      fileUrl: null,
      // DataTable
      totalItem: 0,
      pageCount: 0,
      loading: false,
      options: {
        sortBy: ["name"],
        sortDesc: [false]
      },
      rowsPerPageItems: [10, 20, 30, 40],
      // Form
      imgGroups: [],
      imgTypes: [],
      imgTypesDialog: [],
      imgGroup: null,
      imgType: null
    };
  },
  watch: {
    file(val) {
      console.log(val);
    },
    options: {
      handler() {
        this.fetchListDebounce(this.getDigitalList);
      },
      deep: true
    }
  },
  methods: {
    handleOnChange(val) {
      this.file = val;
      this.fileUrl = URL.createObjectURL(val);
    },
    isImage(ext) {
      return ACCEPT_IMPORT.IMAGE.some(d => d.includes(ext));
    },
    deleteMultiItem() {
      this.$confirm({
        title: "Confirm",
        message: `Are you sure you want to delete ${this.selected.length} item?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            var id_list = [];
            this.selected.map(d => id_list.push(d.id));
            this.deleteDigital({ id_list });
          }
        }
      });
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (!this.file) {
          this.$refs.fileDraggable.openAlert("File harus diisi", "error");
          return;
        }
        let formData = new FormData();
        formData.append("id", 0);
        formData.append("NIP", this.id);
        formData.append("img_group_id", this.form.img_group_id);
        formData.append("image_id", this.form.image_id);
        formData.append("name", this.form.name);
        formData.append("document", this.file);
        this.saveDigital(formData);
      }
    },
    changeGroup(type = "") {
      if (type == "Dialog") {
        this.form.image_id = null;
        if (this.form.img_group_id) {
          this.getListType(this.form.img_group_id, type);
        }
      } else {
        this.imgType = null;
        if (this.imgGroup) {
          this.getListType(this.imgGroup);
        }
      }
    },
    // Service
    async getListGroup() {
      try {
        this.loadingGroup = true;
        await PublicService.getImageGroup()
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.imgGroups = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingGroup = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListType(id, type = "") {
      try {
        this["loadingType" + type] = true;
        await PublicService.getDigitalList(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this["imgTypes" + type] = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this["loadingType" + type] = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getDigitalList() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await PegawaiService.getDigitalList({
          image_id: this.imgType,
          nip: this.id
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let files = list;
              files.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.files = files;
              this.totalItem = parseInt(countTotal);
              this.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveDigital(data) {
      try {
        this.formLoading = true;
        await PegawaiService.saveDigital(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getDigitalList();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
              this.visible = false;
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteDigital(data) {
      try {
        this.formLoading = true;
        await PegawaiService.deleteDigital(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getDigitalList();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getListGroup();
  }
};
</script>
<style lang="scss">
#datatable.v-data-table {
  color: #717171 !important;
}
</style>
